<div id="content" *ngIf="user">
    <p-grid>
        <p-grid-item size="12">
            <p-text size="medium" weight="bold">{{user.name}}</p-text>
        </p-grid-item>
        <p-grid-item size="12">
            <p-text>{{user.email}}</p-text>
        </p-grid-item>
        <p-grid-item size="12">
            <p-text>{{user.corporation}}</p-text>
        </p-grid-item>
        <p-grid-item size="12">
            <p-text>{{user.department}}</p-text>
        </p-grid-item>

        <p-grid-item size="12">
            <p-divider></p-divider>
        </p-grid-item>

        
        <p-grid-item size="12">
            <p-checkbox-wrapper [label]="'workflow.steps.0' | translate" hide-label="false">
                <input type="checkbox" [(ngModel)]="user.authorization.workflowStepAuth[0]" (change)="onChange()" (ngModelChange)="onCheckboxChange($event, 0)"/>
            </p-checkbox-wrapper>
        </p-grid-item>
        <p-grid-item size="12">
            <p-checkbox-wrapper [label]="'workflow.steps.1' | translate" hide-label="false">
                <input type="checkbox" [(ngModel)]="user.authorization.workflowStepAuth[1]" (change)="onChange()" (ngModelChange)="onCheckboxChange($event, 1)"/>
            </p-checkbox-wrapper>
        </p-grid-item>
        <p-grid-item size="12">
            <p-checkbox-wrapper [label]="'workflow.steps.2' | translate" hide-label="false">
                <input type="checkbox" [(ngModel)]="user.authorization.workflowStepAuth[2]" (change)="onChange()" (ngModelChange)="onCheckboxChange($event, 2)"/>
            </p-checkbox-wrapper>
        </p-grid-item>
        <p-grid-item size="12">
            <p-checkbox-wrapper [label]="'workflow.steps.3' | translate" hide-label="false">
                <input type="checkbox" [(ngModel)]="user?.authorization.workflowStepAuth[3]" (change)="onChange()" (ngModelChange)="onCheckboxChange($event, 3)"/>
            </p-checkbox-wrapper>
        </p-grid-item>

        <p-grid-item size="12">
            <p-checkbox-wrapper [label]="'workflow.importInit' | translate" hide-label="false">
                <input type="checkbox" [(ngModel)]="user.authorization.importInit" (change)="onChange()"/>
            </p-checkbox-wrapper>
        </p-grid-item>
        <p-grid-item size="12">
            <p-checkbox-wrapper [label]="'workflow.importReview' | translate" hide-label="false">
                <input type="checkbox" [(ngModel)]="user.authorization.importReview" (change)="onChange()"/>
            </p-checkbox-wrapper>
        </p-grid-item>
        <p-grid-item size="12">
            <p-checkbox-wrapper [label]="'workflow.importConformity' | translate" hide-label="false">
                <input type="checkbox" [(ngModel)]="user.authorization.importConformity" (change)="onChange()"/>
            </p-checkbox-wrapper>
        </p-grid-item>
        <p-grid-item size="12">
            <p-checkbox-wrapper [label]="'workflow.importClosed' | translate" hide-label="false">
                <input type="checkbox" [(ngModel)]="user.authorization.importClosed" (change)="onChange()"/>
            </p-checkbox-wrapper>
        </p-grid-item>

        <p-grid-item size="12">
            <p-checkbox-wrapper [label]="'common.export' | translate" hide-label="false">
                <input type="checkbox" [(ngModel)]="user.authorization.exportAuth" (change)="onChange()"/>
            </p-checkbox-wrapper>
        </p-grid-item>

        <p-grid-item size="12">
            <p-checkbox-wrapper [label]="'common.admin' | translate" hide-label="false">
                <input type="checkbox" [(ngModel)]="user.authorization.adminAuth" (change)="onChange()" [disabled]="user?.sub == userService.currentUser.sub"/>
            </p-checkbox-wrapper>
        </p-grid-item>
    </p-grid>
</div>